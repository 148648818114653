<div class="pre-landing" *ngIf="!loading">
      <div class="title-container">
          <h2 style="font-size: 24px;color: 20623b;">Prenota Appuntamento</h2>
      </div>
      <div class="sub-title-container">
          <h4>Portale Prenotazioni Call Center</h4>
      </div>
      <div class="image-container">
        <img src="./../../../assets/img/block24dp.svg" alt="alt" />
      </div>
      <div style="text-align: center; margin-top: 20px;">
        <h2>Accesso Negato</h2>
        <p>Utente non autorizzato all'accesso</p>
      </div>
</div>
