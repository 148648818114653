import { ROLES } from "../model/user.model";

class Route {
    path: string = "";
    absolute_path: string = "";
    roles: ROLES[] = [];
}

export abstract class ROUTES {
    static readonly PRE_LANDING: Route = {
        path: "",
        absolute_path: "/",
        roles: []
    };

    static readonly NOT_ALLOWED: Route = {
        path: "not-allowed",
        absolute_path: "/not-allowed",
        roles: []
    };

    static readonly HOME: Route = {
        path: "home",
        absolute_path: "/home",
        roles: [ROLES.CALLCENTER_PRENOTAZIONE]
    }

    // Add new Routes here

    static canAccess(roles: ROLES[], route: Route) {
        return route.roles.find(role => {return roles.includes(role)}) != undefined;
    }

    static getRoute(path: string) {
        return Object.entries(ROUTES).find(([key, value]) => {return value.absolute_path == path})
    }
}