import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ROLES } from '../model/user.model';
import { ROUTES } from '../route/routes';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    let roles: ROLES[] = this.userService.getCurrentUserRoles();
    let path = state.url;
    let can_activate = false;
    let route_obj = ROUTES.getRoute(path);

    console.log("ROLE", roles, "vuole accedere a", path);

    if(route_obj) {
      can_activate = roles ? ROUTES.canAccess(roles, route_obj[1]) : false;
    }

    console.log("Can activate?", can_activate);

    if(!can_activate) {
      this.router.navigateByUrl(ROUTES.NOT_ALLOWED.absolute_path);
    }
    
    return can_activate;
  }
  
}
