<div class="pre-landing">
    <div class="intestazione-container">
        <img class="image-footer" src="./../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg">
      </div>
      <div class="title-container">
          <h2 style="font-size: 24px;color: #20623b;">Prenota Appuntamento</h2>
      </div>
      <div class="sub-title-container">
          <h4>Portale Prenotazioni Call Center</h4>
      </div>
      <div class="image-container">
      <img src="./../../../assets/img/calendar.png" alt="calendar" />
      </div>
      <div class="btn-container">
          <button class="btn-submit btn-basic" (click)="goToHome()">Inizia</button>
      </div>
</div>