import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Role, ROLES, User } from '../model/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private profileObs$: BehaviorSubject<User | string | null> = new BehaviorSubject<User | string | null>(null);
  private firebaseUser$: BehaviorSubject<firebase.User | null> = new BehaviorSubject<firebase.User | null>(null);

  private isDoingLogin: boolean = false;

  constructor(private afAuth: AngularFireAuth) {
    this.afAuth.onAuthStateChanged((firebaseUser) => {

      if(firebaseUser) {
        console.log("ALREADY LOGGED");

        if(!firebaseUser.displayName) {
          // Il displayname lo vedi sulla console Firebase, può sempre servire
          firebaseUser.updateProfile({ displayName: firebaseUser.email });
        }

        let user: User | null = null;

        console.log("New Login? ", this.isDoingLogin);

        // State Changed because already logged and Firebase got cookies
        if (!this.isDoingLogin) {
          user = this.retrieveCurrentUser();
          if(!user) {
            this.logout();
          } else {
            this.profileObs$.next(user);
          }
        } else {
          // State Changed because user just logged right now
          // Nothing to do, already managed in the Promise fulfillment
        }

        this.firebaseUser$.next(firebaseUser);
      } else {
        console.log("NOT LOGGED");

        this.profileObs$.next("doLogin");
      }
    });
  }

  getCurrentUser(): Observable<User | string | null> {
    return this.profileObs$.asObservable();
  }

  getCurrentUserRoles(): ROLES[] {
    let tmp = this.profileObs$.getValue();

    if(tmp && typeof tmp !== "string") {
      return tmp.user_roles;
    }

    return [];
  }

  async getCurrentUserToken(): Promise<string | null> {
    let fbUser = this.firebaseUser$.getValue();

    if(fbUser) {
      let token = await fbUser.getIdToken();
      return token;
    }

    return null;
  }

  getCurrentUserUid(): string | null {
    let fbUser = this.firebaseUser$.getValue();
    let uid: string | null = null;

    if(fbUser && fbUser.providerData[0]) {
      uid = fbUser.providerData[0].uid
    }

    return uid;
  }

  private buildCurrentUser(profile: Object): User | null {
    let user: User = new User();
    let raw_roles: string[] = [];
    let roles: ROLES[] = [];

    console.log(profile);

    for(const [key, value] of Object.entries(profile)) {
      if(key == "roles")
        raw_roles = value
      else if(key == "email")
        user.user_email = value
    }

    if(raw_roles) {
      if (Array.isArray(raw_roles)) {
        raw_roles.forEach(role => {
          let new_role = Role.get_Role(role);
          if(new_role) roles.push(new_role);
        });
      } else {
        // Case: single role as string
        let new_role = Role.get_Role(raw_roles);
        if(new_role) roles.push(new_role);
      }
    }

    if(roles) {
      user.user_roles = roles;
    }

    if(user.user_email && user.user_roles) {
      user.is_new_OIDC = true;
      localStorage.setItem('currentUser', JSON.stringify(user));
      return user
    }

    return null
  }

  private retrieveCurrentUser() {
    let user: User = new User();
    let str_user = localStorage.getItem('currentUser');

    if(str_user) {
      user = JSON.parse(str_user);
    }

    if(user.user_email && user.user_roles && user.is_new_OIDC) {
      return user
    }

    return null
  }

  login() {
    const provider: firebase.auth.OAuthProvider = new firebase.auth.OAuthProvider(
      environment.provider
    );

    this.isDoingLogin = true;

    this.afAuth.signInWithPopup(provider).then(
      resp => {
        console.log("Promise OK");
        let user: User | null = null;

        if(resp && resp.additionalUserInfo?.profile) {
          let profile = resp.additionalUserInfo?.profile;
          user = this.buildCurrentUser(profile);
        }

        if(!user) {
          this.logout();
        } else {
          this.profileObs$.next(user);
        }
      },
      err => {
        if(err && err.code == "auth/popup-blocked"){
          this.profileObs$.next("doPopUp");
        }
        
      }
    );

  }

  logout() {

    this.afAuth.signOut().then(
      value => {
        console.log(value);
        this.logoutUserAndRedirect();
      },
      reason => {
        console.log(reason);
        this.logoutUserAndRedirect();
      }
    );
  }

  private logoutUserAndRedirect() {
    this.deleteUserFromLocalStorage();
    sessionStorage.clear();
    console.log("Signed out!");
    if (environment.providerLogout) {
      window.location.href = environment.providerLogout;
    } else {
      window.location.href = '/';
    }
  }

  private deleteUserFromLocalStorage(): void {
    localStorage.removeItem('currentUser');
  }
}
