import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/route/routes';

@Component({
  selector: 'app-pre-landing',
  templateUrl: './pre-landing.component.html',
  styleUrls: ['./pre-landing.component.css']
})
export class PreLandingComponent implements OnInit {

  constructor(private route: Router, private ngZone: NgZone) { }

  ngOnInit() {
  }
  
  goToHome(){
    this.ngZone.run(() => 
      this.route.navigate([ROUTES.HOME.path])
    )
  }
}
