import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLandingComponent } from './component/home-landing/home-landing.component';
import { NotAllowedComponent } from './component/not-allowed/not-allowed.component';
import { PreLandingComponent } from './component/pre-landing/pre-landing.component';
import { ROUTES } from './route/routes';
import { AuthGuard } from './service/auth.guard';

const routes: Routes = [
  {
    path: ROUTES.PRE_LANDING.path,
    component: PreLandingComponent
  },
  {
    path: ROUTES.NOT_ALLOWED.path,
    component: NotAllowedComponent,
    canActivate: [],
  },
  {
    path: ROUTES.HOME.path,
    component: HomeLandingComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

