import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Flow } from '../model/callcenter-form.model';


@Injectable({
  providedIn: 'root'
})
export class ConsultantService {

  constructor(private http: HttpClient) { }

  getJWT(consultant: Flow): Observable<any> {
    let url = environment.adminBackEndUrl + "/reservations/jwt";
    return this.http.post(url, consultant);
  }

  getAgencies(): Observable<any> {
    let param = new HttpParams()
    param = param.set('with_consultants', 'okay')
    let url = environment.adminBackEndUrl + "/agencies?" + param;
    return this.http.get(url)
  }

  getConsultants(idAgency: number): Observable<any> {
    let url = environment.adminBackEndUrl + '/agencies/' + idAgency + '/consultants';
    return this.http.get(url)
  }

}
