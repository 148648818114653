export class User {
    user_email!: string;
    user_roles!: ROLES[];
    user_uid!: string;
    is_new_OIDC: boolean | undefined = undefined;
}

export abstract class Role {

    static get_Role(myRole: string): ROLES | undefined {
        const match = Object.entries(ROLES).find(([key, value]) => myRole.indexOf(value) > -1);
        return match ? match[1] : undefined
    }
}

export enum ROLES {
    FILIALE = "CONSOLE_ADMIN.ACCESSO.FILIALE",
    BANCA = "CONSOLE_ADMIN.ACCESSO.BANCA",
    ADMIN = "CONSOLE_ADMIN.ACCESSO.SUPERUSER",
    CALLCENTER_ADMIN = "CONSOLE_ADMIN.ACCESSO.CALLCENTER",
    CALLCENTER_PRENOTAZIONE = "PRENOTA_PROSPECT.ACCESSO.CALLCENTER"
}