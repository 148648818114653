import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Flow } from 'src/app/model/callcenter-form.model';

import { ROUTES } from 'src/app/route/routes';
import { ConsultantService } from 'src/app/service/consultant.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-home-landing',
  templateUrl: './home-landing.component.html',
  styleUrls: ['./home-landing.component.scss']
})
export class HomeLandingComponent implements OnInit {

  isLoading: boolean = true;
  btnDisabled: boolean = true;
  isConsultant: boolean = false;

  private isValidAgency!: boolean;
  private isValidConsultant!: boolean;
  private isValidName!: boolean;
  private isValidSurname!: boolean;
  private isValidCodFiscale!: boolean;
  private isValidEmail!: boolean;
  private isValidSecondEmail!: boolean;
  private isValidNumber!: boolean;

  errorName: boolean = false;
  errorSurname: boolean = false;
  errorCodFiscale : boolean = false;
  errorEmail: boolean = false;
  errorSecondEmail: boolean = false;
  errorNumber: boolean = false;

  CHOOSE_CONSULTANT= 'choose_consultant';

  gestore: any[] = [];
  gestoreId: any;

  agencyList: Array<{id_agency: number, name: string}> = [];
  filteredAgencies: Array<{id_agency: number, name: string}> = [];
  inputAgencyName: string = "";
  selectedAgencyId: number | undefined;

  data: Flow = new Flow();
  secondEmail: string = "";

  constructor(
    private route: Router,
    private consultantService: ConsultantService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getAgencies();
  }

  getAgencies() {
    this.consultantService.getAgencies().subscribe(res => {
      this.agencyList = res.agencies;
      this.filteredAgencies = res.agencies;
      this.isLoading = false;
    })
  }

  doFilter() {
    this.filteredAgencies = this.agencyList.filter(agency_obj => {return agency_obj.name.toLowerCase().includes(this.inputAgencyName.toLowerCase())});
  }

  selectAgency() {
    this.selectedAgencyId = this.agencyList.find(el => {return el.name == this.inputAgencyName})?.id_agency;

    if (!this.selectedAgencyId) {
      this.isValidAgency = false;
    } else {
      this.isValidAgency = true;

      this.consultantService.getConsultants(this.selectedAgencyId).subscribe(res => {
        this.gestore = res.consultants;
        this.isConsultant = true;
      });
    }

    this.buttonDisabled();
  }

  goBack() {
    this.route.navigate([ROUTES.PRE_LANDING.path]);
  }

  private buttonDisabled() {
    if(
      this.isValidName &&
      this.isValidSurname &&
      this.isValidCodFiscale &&
      this.isValidEmail &&
      this.isValidSecondEmail &&
      this.isValidNumber &&
      this.isValidAgency &&
      this.isValidConsultant
      ) {
        this.btnDisabled = false;
      } else {
        this.btnDisabled = true;
      }
  }

  validationConsultant(obj: any) {
    if(obj.id_consultant_operator == undefined) {
      this.isValidConsultant = false;
    } else {
      this.isValidConsultant = true;
    }

    this.buttonDisabled();
  }

  validationName() {
    if(this.data.jwt.clienteNome.length == 0) {
      this.isValidName = false;
    } else if(this.data.jwt.clienteNome.length < 2) {
      this.errorName = true;
      this.isValidName = false;
    } else {
      this.errorName = false;
      this.isValidName = true;
    }

    this.buttonDisabled();
  }

  validationSurname() {
    if(this.data.jwt.clienteCognome.length == 0) {
      this.isValidSurname = false;
    } else if(this.data.jwt.clienteCognome.length < 2) {
      this.errorSurname = true;
      this.isValidSurname = false;
    } else {
      this.errorSurname = false;
      this.isValidSurname = true;
    }

    this.buttonDisabled();
  }

  validationCodFiscale() {
    const CodFiscale = /[A-Z]{6}[\d]{2}[A-Z][\d]{2}[A-Z][\d]{3}[A-Z]/;
    this.data.jwt.clienteCodiceFiscale = this.data.jwt.clienteCodiceFiscale.toUpperCase();

    if(this.data.jwt.clienteCodiceFiscale.length == 0) {
      this.isValidCodFiscale = false;
    } else if(this.data.jwt.clienteCodiceFiscale.length != 16) {
      this.errorCodFiscale = true;
      this.isValidCodFiscale = false;
    } else if(this.data.jwt.clienteCodiceFiscale.match(CodFiscale)) {
      this.errorCodFiscale = false;
      this.isValidCodFiscale = true;
    } else {
      this.errorCodFiscale = true;
      this.isValidCodFiscale = false;
    }

    this.buttonDisabled();
  }

  validationEmail() {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if(this.data.jwt.clienteEmail.length == 0) {
      this.isValidEmail = false;
    } else {
      if(this.data.jwt.clienteEmail.match(mailformat)) {
        this.errorEmail = false;
        this.isValidEmail = true;
      } else {
        this.errorEmail = true;
        this.isValidEmail = false;
      }

      this.validationSecondEmail();

    }

    this.buttonDisabled();
  }

  validationSecondEmail() {
    if(this.secondEmail.length == 0) {
      this.isValidSecondEmail = false;
      this.errorSecondEmail = false;
    } else if (this.data.jwt.clienteEmail != this.secondEmail) {
      this.isValidSecondEmail = false;
      this.errorSecondEmail = true;
    } else {
      this.isValidSecondEmail = true;
      this.errorSecondEmail = false;
    }

    this.buttonDisabled();
  }

  validationNumber() {
    const numberformat = /^[\d]{9,10}$/;

    if(this.data.jwt.clienteCellulare.length == 0) {
      this.isValidNumber = false;
    } else if(this.data.jwt.clienteCellulare.match(numberformat)) {
      this.errorNumber = false;
      this.isValidNumber = true;
    } else {
      this.errorNumber = true;
      this.isValidNumber = false;
    }

    this.buttonDisabled();
  }

  save() {
    this.data.jwt.filialeIdentificativo = this.selectedAgencyId;
    this.data.jwt.gestoreIdentificativo = this.gestoreId.id_consultant_operator;
    this.data.jwt.operatoreMatricola = this.userService.getCurrentUserUid();

    this.isLoading = true;
    this.consultantService.getJWT(this.data).subscribe(
      res => {
        if (res) {
          window.location.href = res
        }
      },
      error => {
        if(error) {
          this.isLoading = false;
        }
      }
    )
  }

}
