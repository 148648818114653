
export class Flow {
  flow!: string;
  jwt!: CallcenterForm
  constructor() {
    this.flow = FLOWS.GESTORE;
    this.jwt = new CallcenterForm();
  }
}

class CallcenterForm {
    filialeIdentificativo!: any;
    gestoreIdentificativo!: any;
    clienteNome!: string;
    clienteCognome!: string;
    clienteCodiceFiscale!: string;
    clienteEmail!: string;
    clienteCellulare!: string;
    operatoreMatricola: string | null;
    canaleUtilizzo: string;

    constructor() {
        this.filialeIdentificativo = '';
        this.gestoreIdentificativo = '';
        this.clienteNome = '';
        this.clienteCognome = '';
        this.clienteCodiceFiscale = '';
        this.clienteEmail = '';
        this.clienteCellulare = '';
        this.operatoreMatricola = ''
        this.canaleUtilizzo = CHANNEL.CALL_CENTER
    }
}

export enum FLOWS {
    GESTORE = "GESTORE"

}

export enum CHANNEL {
    IB_az = "COR",
    IB_priv = "VIR",
    MOBILE_az = "MAZ",
    MOBILE_priv = "MOB",
    CALL_CENTER = "VIC",
    QRCODE = "QRC",
    PUBLIC = "STP",
    NULL = "N/A"
}
