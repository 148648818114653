<div *ngIf="isLoading">
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="home-landing">
  <div class="intestazione-container">
    <div class="back-btn-container" style="width: 33.3%;">
      <button class="btn-summary d-none d-md-block" (click)="goBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <p class="back-written d-none d-md-block" (click)="goBack()">indietro</p>
    </div>
    <img style="width: 33.3%;" class="image-footer" src="../../../assets/img/Logo-Credem-Wellbanking_rgb_alta.jpg" />
    <div style="width: 33.3%;"></div>
  </div>
  <div class="progress-container">
    <mat-progress-bar mode="determinate" value="25"></mat-progress-bar>
  </div>
  <div class="title-container">
    <h2>Compila i seguenti campi</h2>
  </div>
  <div class="select-container row">

    <!--div class="div-container">
      <div class="width-div">
        <mat-form-field appearance="legacy">
          <mat-label>Filiale</mat-label>
          <mat-select [(ngModel)]="filialeId" (selectionChange)="validationAgency(filialeId)">
            <mat-option [value]="CHOOSE_AGENCY" selected disabled>
              Scegli una filiale
            </mat-option>
            <mat-option *ngFor="let item of filiale" [value]="item.id_agency">
              {{item.code}} - {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="errorInput">
      </div>
    </div-->

    <div class="div-container">
      <div class="width-div">
        <mat-form-field class="my-form-field-color" appearance="fill">
          <mat-label>Filiale</mat-label>
          <input type="text" placeholder="Inserisci" aria-label="Text" matInput name="agencyAutocomplete"
            [(ngModel)]="inputAgencyName" (ngModelChange)="doFilter()" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAgency()">
            <mat-option *ngFor="let agency of filteredAgencies" [value]="agency.name">
              {{agency.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div class="div-container">
      <div class="width-div">
        <mat-form-field appearance="legacy">
          <mat-label>Gestore</mat-label>
          <mat-select [disabled]="!isConsultant" [(ngModel)]="gestoreId"
            (selectionChange)="validationConsultant(gestoreId)">
            <mat-option [value]="CHOOSE_CONSULTANT" selected disabled>
              Scegli un gestore
            </mat-option>
            <mat-option *ngFor="let item of gestore" [value]="item">
              {{item.name}} {{item.surname}} - {{item.matricola}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="errorInput">
      </div>
    </div>

    <div class="div-container">
      <div class="width-div">
        <div class="errorInput">

        </div>
      </div>
    </div>
  </div>

  <div class="select-container row">
    <div class="div-container">
      <div class="width-div">
        <mat-form-field appearance="legacy">
          <mat-label>Nome Cliente</mat-label>
          <input matInput placeholder="Es. Mario" autocomplete="off" (input)="validationName()"
            [(ngModel)]="data.jwt.clienteNome">
        </mat-form-field>
      </div>
      <div class="errorInput">
        <p *ngIf="errorName">Nome non valido</p>
      </div>
    </div>

    <div class="div-container">
      <div class="width-div">
        <mat-form-field appearance="legacy">
          <mat-label>Cognome Cliente</mat-label>
          <input matInput placeholder="Es. Rossi" autocomplete="off" (input)="validationSurname()"
            [(ngModel)]="data.jwt.clienteCognome">
        </mat-form-field>
      </div>
      <div class="errorInput">
        <p *ngIf="errorSurname">Cognome non valido</p>
      </div>
    </div>

    <div class="div-container">
      <div class="width-div">
        <mat-form-field appearance="legacy">
          <mat-label>Codice Fiscale</mat-label>
          <input matInput autocomplete="off" maxlength="16" (input)="validationCodFiscale()"
            [(ngModel)]="data.jwt.clienteCodiceFiscale">
        </mat-form-field>
      </div>
      <div class="errorInput">
        <p *ngIf="errorCodFiscale">Codice fiscale non valido</p>
      </div>
    </div>
  </div>

  <div class="select-container row">

    <div class="div-container">
      <div class="width-div">
        <mat-form-field appearance="legacy">
          <mat-label>Email</mat-label>
          <input matInput autocomplete="new-password" (input)="validationEmail()" [(ngModel)]="data.jwt.clienteEmail">
        </mat-form-field>
      </div>
      <div class="errorInput">
        <p *ngIf="errorEmail">Email non valida</p>
      </div>
    </div>

    <div class="div-container">
      <div class="width-div">
        <mat-form-field appearance="legacy">
          <mat-label>Ripeti Email</mat-label>
          <input matInput autocomplete="false" (input)="validationSecondEmail()" [(ngModel)]="secondEmail" onpaste="return false" ondrop="return false">
        </mat-form-field>
      </div>
      <div class="errorInput">
        <p *ngIf="errorSecondEmail">Le due email non coincidono</p>
      </div>
    </div>

    <div class="div-container">
      <div class="width-div">
        <mat-form-field appearance="legacy">
          <mat-label>Numero di telefono</mat-label>
          <input matInput autocomplete="off" maxlength="10" (input)="validationNumber()"
            [(ngModel)]="data.jwt.clienteCellulare">
        </mat-form-field>
      </div>
      <div class="errorInput">
        <p *ngIf="errorNumber">Numero non valido</p>
      </div>
    </div>

  </div>

  <div class="submit-btn">
    <button [disabled]="btnDisabled" [ngClass]="['btn-basic', !btnDisabled ? 'btn-submit' : 'btn-disabled']"
      (click)="save()">
      Procedi
    </button>
  </div>
</div>
