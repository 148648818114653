import { ChangeDetectorRef, Component } from '@angular/core';
import { User } from './model/user.model';
import { UserService } from './service/user.service';
import { takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ROUTES } from './route/routes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  user! : User;
  loading: boolean = true;
  testCookies: boolean = false;

  allowPopupMessage: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.router.navigateByUrl(ROUTES.PRE_LANDING.absolute_path);

    this.checkAuth();
  }

  checkAuth() {
    this.userService
      .getCurrentUser()
      .pipe(
        takeWhile((value) => {
          return !(value instanceof User);
        }, true)
      )
      .subscribe(
        (user) => {
          if (user) {
            if (typeof user === 'string') {
              if (user === 'doLogin') {
                this.userService.login();
              }

              if (user === 'doPopUp') {
                this.allowPopupMessage = true;
              } else {
                this.allowPopupMessage = false;
              }
              this.changeDetectorRef.detectChanges();
            } else {
              this.user = user;
              this.loading = false;
              this.changeDetectorRef.detectChanges();
            }
          }
        },
        (err) => {
          console.error(err);

        }
      );
  }

  logout() {
    this.userService.logout();
  }
}
